import React from 'react'
import Img from 'gatsby-image'
import './modal.scss'

class MarkdownModalImage extends React.Component {
  constructor(props) {
    super(props)
    this.rehyped = props.rehyped
    this.className = props.className
    this.linktext = props.linktext
  }
  
  state = {
    showImage: false
  }

  showImage = () => {
    this.setState({showImage: true})
  }

  hideImage = () => {
    this.setState({showImage: false})
  }
  
  render () {
    const props = JSON.parse(this.rehyped);

    return (
        <>
          <div className="open-modal" onClick={this.showImage}>
            {this.linktext}
          </div>
          <div className={"modal fade " + (this.state.showImage ? "shown" : "hidden")} tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <label className="modal-backdrop fade" for="modal-switch"></label>

            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title modal-switch">{this.linktext}</h4>
                  <label for="modal-switch" className="close" data-dismiss="modal" onClick={this.hideImage} aria-label="Close">
                    <span aria-hidden="true">
                      &times;
                    </span>
                  </label>
                </div>
                <div className="modal-body">
                  <Img
                    fluid={{ ...props, sizes: "(max-width: 1000px) 100vw, 1000px" }}
                    className={this.className}
                  />
                </div>
              </div>
            </div>
          </div>
        </>      
    );
  }
  
}
export default MarkdownModalImage